import { baseAxiosInstance } from "../../api/axios";
import { AxiosRequestConfig } from "axios";

export const deleter = async (url: string, config?: AxiosRequestConfig) => {
  try {
    const response = await baseAxiosInstance.delete(url, config);
    return response.data;
  } catch (error: any) {
    if (error.response?.data) {
      throw error.response?.data;
    } else {
      throw new Error("something went wrong");
    }
  }
};
