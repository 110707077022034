import { Suspense } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { PublicRoute } from "../components/PublicRoute";
import { publicRoutes } from "./routes";
import { Box } from "@mui/system";
import { LinearProgress } from "@mui/material";

const Routes = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        }
      >
        <Switch>
          {publicRoutes.map(({ Component, ...rest }, i) => (
            <PublicRoute key={i} {...rest}>
              <Component />
            </PublicRoute>
          ))}
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
