import login from "./auth/login/en";
import forgotPassword from "./auth/forgot-password/en";
import resetPassword from "./auth/reset-password/en";
import register from "./auth/register/en";

const locales = {
  auth: {
    login,
    forgotPassword,
    resetPassword,
    register,
  },
};

export default locales;
