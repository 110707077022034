import { atom } from "recoil";
import { localstorageKeys } from "../services/local-storage-keys";

export const macState = atom<string | undefined>({
  key: "mac",
  default:
    (sessionStorage.getItem(localstorageKeys.mac) as string) ?? undefined,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((mac: string | undefined) => {
        if (mac) {
          sessionStorage.setItem(localstorageKeys.mac, mac);
        }
      });
    },
  ],
});
