import { atom } from "recoil";
import { localstorageKeys } from "../services/local-storage-keys";

export const serviceState = atom<"enabled" | "disabled" | undefined>({
  key: "service",
  default:
    (sessionStorage.getItem(localstorageKeys.service) as
      | "enabled"
      | "disabled") ?? undefined,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((service: "enabled" | "disabled" | undefined) => {
        if (service) {
          sessionStorage.setItem(localstorageKeys.service, service);
        }
      });
    },
  ],
});
