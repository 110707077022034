const verifyToken = (token: string) => `/account/verify/${token}`;
const resetPassword = "/account/reset-password";
const register = "/account";
const device = "/devices";
const verifyCode = (code: string) => `/account/verify-code/${code}`;
const resendCode = "/account/resend-validation-code";
const reEnterEmail = "/account/email";
const companyInfo = "/account/company-info";
const firstLogin = "/staff/login";

const policyForUserPerBranch = (userId: string, branchId: string) =>
  `internet-management/policy/${userId}/${branchId}`;

const metaData = (branchId: string) => `/account/login-meta-data/${branchId}`;
const deviceByMac = (macAddress: string) => `/devices/${macAddress}`;
const sendVerificationCode = (phone: string, method: string) =>
  `/account/verification-code/${phone}/${method}`;

const resendVerificationCode = (
  phone: string,
  method: string,
  branchId?: string
) =>
  `/account/verification-code/resend/${phone}/${method}${
    branchId ? `?branchId=${branchId}` : ""
  }`;
const verifyPhoneNumber = `/account/verification-code/verify`;

const notMe = (mac: string) => `/devices/${mac}`;

// Marketing
const ads = (branchId: string) => `/login-page-ads/end-user/${branchId}`;

const setAdView = `/login-page-ads/end-user/add-view`;

const CheckIfUserHasAccessCode = `/access-codes/check-existence`;

const verifyAccessCode = `/access-codes/verify`;

export const ServerAPI = {
  CheckIfUserHasAccessCode,
  verifyAccessCode,
  metaData,
  deviceByMac,
  sendVerificationCode,
  resendVerificationCode,
  verifyPhoneNumber,
  verifyToken,
  resetPassword,
  register,
  device,
  verifyCode,
  policyForUserPerBranch,
  resendCode,
  reEnterEmail,
  companyInfo,
  firstLogin,
  notMe,
  marketing: {
    ads,
    setAdView,
  },
};
