/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  styled,
  Link,
} from "@mui/material";
import { Logo } from "../../components/Logo";
import NoInternetIcon from "../../assets/noInternet.svg";
import { themeState } from "../../store/theme";
import { useRecoilState } from "recoil";

const DisabledService: React.FC = () => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [builderTheme] = useRecoilState(themeState);

  const StyledLogo = styled("img")({
    width: "85px",
    height: "85px",
    borderRadius: "50%",
    marginBlockEnd: "12px",
    border: "solid 4px",
    borderColor: theme.palette.divider,
  });

  const CustomLink = styled(Link)({
    fontWeight: 600,
    color: theme.palette.text.primary,
    cursor: "pointer",
    textDecorationColor: theme.palette.text.primary,
  });

  return (
    <Box
      p={2}
      mt={isSmDown ? 12 : 0}
      ml={isSmDown ? 1 : 0}
      mr={isSmDown ? 1 : 0}
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems={"flex-start"}
    >
      <Box
        style={{ width: "50rem", marginTop: "24px" }}
        display="flex"
        alignItems={"center"}
        flexDirection="column"
      >
        {builderTheme && (
          <StyledLogo src={builderTheme.logo} alt="company-logo" />
        )}

        <Typography mb={1} variant={"h4"} fontWeight={500}>
          Welcome
        </Typography>

        <Box
          sx={[
            {
              display: "flex",
              position: isSmDown ? "absolute" : "relative",
              top: "0px",
              alignItems: "center",
              p: 1,
              bgcolor: "#eff7ff",
              borderRadius: "4px",
              mb: 2,
              height: "36px",
            },
            isSmDown
              ? { width: "100%", right: "0px", justifyContent: "center" }
              : {},
          ]}
        >
          <img
            src={require("../../assets/logo.webp")}
            style={{
              height: "20px",
              marginInlineEnd: "4px",
            }}
            alt="logo"
          />
          <Typography variant={"caption"} fontWeight={400} color="text.primary">
            Powered & secured by our partners at{" "}
            <CustomLink
              href="https://winfico.com/"
              target="_blank"
              variant={"body2"}
            >
              WinFi
            </CustomLink>
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            border: "1px solid",
            borderColor: "divider",
            borderRadius: "12px",
            p: 3,
          }}
        >
          <Box
            sx={{
              width: "100",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              mb={3}
              variant={"body1"}
              fontWeight={600}
              style={{ textAlign: "center" }}
            >
              This venue doesn’t have <br /> active WinFi services
            </Typography>
            <img alt="speed-test-icon" src={NoInternetIcon} />
            <Typography
              mt={2}
              variant={"body1"}
              fontWeight={400}
              textAlign="center"
            >
              Please get back to the venue administration to help you further.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DisabledService;
